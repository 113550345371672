import styled from "styled-components";
import { Form } from "@unform/web";
import { MdCheck } from "react-icons/md";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ContainerLeft = styled.div`
  background-color: #77aa77;
  background-image: url("https://condominioappstorage.blob.core.windows.net/conectedu/Estartando/aluno-estartando.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fea000;
  overflow: hidden;  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  /* background-image: url("https://images.pexels.com/photos/5088178/pexels-photo-5088178.jpeg"); */
  width: 100%;
  height: 100vh;
  /* background-size: cover; */
  align-items: center;
  /* background-attachment: fixed; */

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 310px;
  justify-content: space-around;
  @media (max-width: 720px) {
    width: initial;
  }
`;

const ContainerAuth = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-right: 30px;
  padding: 60px 30px;
  position: relative;
  @media (max-width: 1024px) {
    /* width: 100%; */
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

const SentEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 400px;
`;
const IconSentEmail = styled(MdCheck)`
  font-size: 4em;
  color: green;
`;

const BoxBackLogin = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 15px;
`;

export {
  Container,
  ContainerLeft,
  ContainerAuth,
  FormContainer,
  BoxText,
  SentEmail,
  IconSentEmail,
  BoxBackLogin,
};
